<template>
    <div class="has-hero-container">
        <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
        <div class="sp-hero sp-bg-light-blue">
            <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

                <app-filter-apply-button
                    v-if="filterStore.hasChanges"
                    @click="applyFilter(); options.page = 1"
                ></app-filter-apply-button>

                <v-slide-group show-arrows>
                    <v-slide-item>
                        <div class="filter-item">
                            <app-filter-date-picker
                                :filterLabel="$t('components.qc-notifications-filters.from')"
                                v-model="filterStore.filter.dateStart"
                                data-cy="dateStart"
                                id="dateStart"
                                :clearable="true"
                            ></app-filter-date-picker>
                        </div>
                    </v-slide-item>

                    <v-slide-item>
                        <div class="filter-item">
                            <app-filter-date-picker
                                :filterLabel="$t('components.qc-notifications-filters.to')"
                                v-model="filterStore.filter.dateEnd"
                                data-cy="dateEnd"
                                id="dateEnd"
                                :clearable="true"
                            ></app-filter-date-picker>
                        </div>
                    </v-slide-item>

                    <v-slide-item>
                        <div class="filter-item filter-search-list">
                            <app-filter-search-field
                                v-model="filterStore.filter.searchTerm"
                                :filterLabel="$t('components.qc-notifications-filters.search-label')"
                                clearable
                                append-icon="mdi-magnify"
                                :placeholder="$t('components.qc-notifications-filters.search-placeholder')"
                            ></app-filter-search-field>
                        </div>
                    </v-slide-item>

                    <v-slide-item>
                        <div class="filter-item filter-status-filter">
                            <q-c-notifications-status-filter :multiple="true" v-model="filterStore.filter.status" data-cy="status"/>
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </v-container>
        </div>

        <v-container fluid>
            <v-row class="data-table-toolbar">
            <v-spacer></v-spacer>
                <div v-show="dataLoaded && items.length > 0">
                    <v-btn
                        outlined
                        color="primary"
                        elevation="0"
                        class="icon-left"
                        :disabled="!readyToDownload"
                        :loading="isDownloadingExport"
                        @click="exportExcel()"
                        data-cy="export-excel"
                    >
                        <v-icon>mdi-download</v-icon>
                        {{ $t('qc-notifications.list.excel-export') }}
                    </v-btn>
                </div>

                <div>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :disabled="!readyToDownload"
                        :to="{ path: '/qc_notifications/create'}"
                        data-cy="createButton"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('qc-notifications.list.create') }}
                    </v-btn>
                </div>
            </v-row>

            <v-row v-if="dataLoaded && items.length === 0">
                <div class="empty-datatable">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    {{ t('no-data') }}
                </div>
            </v-row>

            <v-row v-else>
                <v-data-table
                    calculate-widths
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :loading="loading"
                    class="elevation-0"
                    :sort-by.sync="filterStore.table.sortBy"
                    :sort-desc.sync="filterStore.table.sortDesc"
                    :page.sync="filterStore.table.currentPage"
                    :items-per-page.sync="filterStore.table.itemsPerPage"
                    :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-options': [25, 50, 100, 200],
                        pageText: '{0}-{1} von {2}',
                        'items-per-page-text': t('table.items-per-page')
                    }"
                >
                    <template v-slot:[`item.q_c_notifications.created_at`]="{ item }">
                        <span>{{ formatDate(item) }}</span>
                    </template>
                    
                    <template v-slot:[`item.tasks`]="{ item }">
                        <span>{{ getTasksCounter(item.tasks) }}</span>
                    </template>
                    
                    <template v-slot:[`item.status`]="{ item }">
                        <StatusIcons :status="item.status"/>
                    </template>

                    <template v-slot:[`item.edit`]="{ item }"  >
                        <router-link :to="{ path: '/qc_notifications/' + item.id }" class="edit-row-table" data-cy="editButton">
                        <v-icon
                            small
                            class="edit-row-button"
                        >
                            edit
                        </v-icon>
                        </router-link>
                    </template>
                    
                </v-data-table>
            </v-row>

        </v-container>
        
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import moment from 'moment'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import StatusIcons from '@/components/layouts/StatusIcons'
import QCNotificationsStatusFilter from '@/components/filter-components/QCNotificationsStatusFilter'
import { useQCNotificationsFilterStore } from '@/stores/QCNotificationsFilterStore'

export default {
    name: 'QCNotificationsIndex',
    components: {
        toolbar,
        StatusIcons,
        AppFilterDatePicker,
        AppFilterSearchField,
        AppFilterApplyButton,    
        QCNotificationsStatusFilter
    },
    setup() {
        const filterStore = useQCNotificationsFilterStore()
        return { filterStore }
    },
    data() {
        return { 
            configuration: null,
            loading: false,
            isDownloadingExport: false,
            isExporting: false,
            exportStats: {
                approved: null,
            },
            totalItems: 0,
            items: [],
            options: {},
            dataLoaded: false,
            status: ''
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.t('table.client'),
                    align: 'start',
                    sortable: true,
                    value: 'customers.name',
                    width: 250,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.date'),
                    value: 'q_c_notifications.created_at',
                    sortable: true,
                    width: 120,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.leader'),
                    value: 'leader.name',
                    sortable: true,
                    width: 140,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.object_type'),
                    value: 'notification_rooms.title',
                    sortable: true,
                    width: 120,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.subject_area'),
                    value: 'subject_areas.title',
                    sortable: true,
                    width: 140,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.notification_rating'),
                    value: 'notification_ratings.title',
                    sortable: true,
                    width: 140,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.tasks'),
                    value: 'tasks',
                    sortable: false,
                    width: 120,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.status'),
                    value: 'status',
                    width: 75,
                    align: 'center',
                    sortable: false
                },
                {
                    text: this.t('table.edit'),
                    value: 'edit',
                    width: 75,
                    align: 'center',
                    sortable: false
                }
            ]
        }
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    methods: {
        t: function (key) {
            return this.$t('qc-notifications.list.' + key)
        },
        exportExcel() {
            this.isDownloadingExport = true

            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

            HTTP.post('dataexport/excel/qc_notifications' + url, {}, { responseType: 'arraybuffer' }).then(function (response) {
                let headers = response.headers
                let blob = new Blob([response.data], {type: headers['content-type']})
                let link = document.createElement('a')
                link.style = 'display: none'
                link.href = window.URL.createObjectURL(blob)
                link.download = 'QualityControlNotifications' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
                document.body.appendChild(link)
                link.click()
                setTimeout(function () {
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(blob)
                }, 100)
                this.isDownloadingExport = false
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.isDownloadingExport = false
            }.bind(this))
        }, 
        moment: function () {
            return moment()
        },
        applyFilter() {
            this.filterStore.filterApplied()
            this.reload()
        },
        reload() {
            this.items = []
            this.getDataFromApi()
        },
        getDataFromApi() {
            this.loading = true
            this.dataLoaded = false
            this.apiCall().then(data => {
                this.items = data.items
                this.totalItems = data.total
                this.loading = false
                this.dataLoaded = true
            })
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const {sortBy, sortDesc, page, itemsPerPage} = this.options

                let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

                HTTP.get('qc_notifications' + url).then(function (response) {
                let items = response.data.data;
                let total = response.data.total;

                resolve({
                    items,
                    total,
                })
                }.bind(this))

            })
        },
        prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
            let queryStringParts = []
            if (this.filterStore.filter.dateStart) {
                queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
            }
            if (this.filterStore.filter.dateEnd) {
                queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
            }

            if (this.filterStore.filter.searchTerm) {
                queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
            }

            if (this.filterStore.filter.status.length > 0) {
                queryStringParts.push('st=' + this.filterStore.filter.status)
            }
            if (sortBy) {
                queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
                let direction = sortDesc[0] ? 'desc' : 'asc'
                queryStringParts.push('direction=' + direction)
            }
            page = page ? page : 1
            queryStringParts.push('page=' + page)
            queryStringParts.push('itemsPerPage=' + itemsPerPage)
            return '?' + queryStringParts.join('&')
        },
        readyToDownload: function () {
            return this.dataLoaded && !this.isDownloadingExport
        },
        getTasksCounter(tasks) {
            let completedtasks = tasks.filter((task) => { return task.status === 'DONE' })
            return completedtasks.length + '/' + tasks.length
        }
    }
}
</script>

<style scoped lang="scss">
    .filter-status-filter {
        width: calc(40.33% - 134px) !important 
    }
</style>